import React from 'react';

import UserListItem from 'components/UserListItem';
import { StarCardUserFields } from 'types/src/api/models/user';

const UserList = (
  user: StarCardUserFields,
  analyticsFunction: (event: React.MouseEvent) => void
) => (
  <div className="col-md-4 col-sm-6" onClick={analyticsFunction} key={user._id}>
    <UserListItem
      name={user.name}
      id={user.username}
      profession={user.profession}
      imageUrlKey={user.imageUrlKey}
    />
  </div>
);

export default UserList;
