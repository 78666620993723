import { media, sizing } from 'design-system/style';
import styled from 'styled-components';

export const CategoryCard = styled.div`
  width: ${sizing(212)};
  flex: 0 0 ${sizing(212)};

  @media (min-width: ${media.queries.TABLET}) {
    width: ${sizing(240)};
    flex: 0 0 ${sizing(240)};
  }
`;

export const CategoryList = styled.div`
  min-width: ${sizing(250)};
  margin-bottom: ${sizing(10)};
`;

export const CategoryGroupedCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${sizing(12)};

  @media (min-width: ${media.queries.DESKTOP}) {
    display: block;
  }
`;

export const CategoryCircle = styled.div`
  display: flex;
`;
