import BrowseLink from 'components/BrowseRow/BrowseLink';
import { BrowseLink as BrowseLinkType } from 'types/src/api/endpoints/explore/utils';
import { BrowseRowHeaderVariant } from 'types/src/api/endpoints/explore/browseRow';
import ScrollArrows from 'components/BrowseRow/CollectionView/ScrollArrows';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'domains/web/components';

import { useSelector } from 'react-redux';
import { isKidsPage as isKidsPageSelector } from 'state/modules/router/selectors';
import * as Styled from './Styled';
import { VisibilityContext } from '../VisibilityContext';

type Props = {
  title: BrowseLinkType;
  onBrowseLinkClick: (titleText?: string) => void;
  headerVariant: BrowseRowHeaderVariant;
  shouldDisplayArrows?: boolean;
  isCollectionV2?: boolean;
};

export const RowHeader = ({
  title,
  onBrowseLinkClick,
  headerVariant,
  shouldDisplayArrows = false,
  isCollectionV2,
}: Props) => {
  const { t } = useTranslation('BrowseRow');
  const { linkType, linkKey, externalUrl } = title;
  const isMerchRow = linkType === 'external' && externalUrl?.includes('merch');

  const showPagination = useSelector(isKidsPageSelector);
  const { currentPage, totalPages } = useContext(VisibilityContext);

  const hideActions = showPagination && totalPages === 1;

  const getSeeAllLinkText = () => {
    if (linkType === 'search') return t('CollectionView.seeMore', 'See more');
    if (linkKey === 'categories' || isMerchRow) {
      return t('CollectionView.viewAll', 'View all');
    }

    return t('CollectionView.seeAll', 'See all');
  };

  return (
    <Styled.TitleContainer>
      <Text variant="title_m" fontWeight="bold" as="h2">
        {title.text}
      </Text>
      <Styled.ScrollRowActions hideActions={hideActions}>
        {!showPagination && title && (title.linkKey || title.externalUrl) && (
          <div>
            <BrowseLink
              text={title.text}
              linkKey={title.linkKey}
              linkType={title.linkType}
              externalUrl={title.externalUrl}
              openInNewTab={isMerchRow}
              onClick={() => onBrowseLinkClick(getSeeAllLinkText())}
            >
              {isCollectionV2 ? (
                <Styled.SeeAllLinkV2>{getSeeAllLinkText()}</Styled.SeeAllLinkV2>
              ) : (
                <Styled.SeeAllLink>{getSeeAllLinkText()}</Styled.SeeAllLink>
              )}
            </BrowseLink>
          </div>
        )}
        {showPagination && !!currentPage && !!totalPages && (
          <Styled.Paginator>{`${currentPage}/${totalPages}`}</Styled.Paginator>
        )}
        {shouldDisplayArrows && <ScrollArrows />}
      </Styled.ScrollRowActions>
    </Styled.TitleContainer>
  );
};
