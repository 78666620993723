import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BREAKPOINT = 150; // 150ms

// Decides if the onClick function should be called or not
// It fixes the error on drag and scroll when you click inside a link to drag
class DragAwareLink extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    openInNewTab: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => null,
    className: null,
    openInNewTab: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      onMouseDownDate: new Date(),
    };
  }

  startTimer = () => {
    this.setState({
      onMouseDownDate: new Date(),
    });
  };

  executeOnClickFunctionIfNecessary = (event) => {
    const onMouseUpDate = new Date();
    const millisecondsBetweenEvents =
      onMouseUpDate.getTime() - this.state.onMouseDownDate.getTime();

    if (millisecondsBetweenEvents > BREAKPOINT) {
      // This prevents the event from making the redirect action from happening
      event.preventDefault();
    } else {
      this.props.onClick(event);
    }
  };

  render() {
    // For <Link />, onClick is run before the navigation, so if
    // we prevent the default action here when we don't want the
    // redirect to be made, it won't occur!
    return (
      <Link
        target={this.props.openInNewTab ? '_blank' : '_self'}
        to={this.props.to}
        className={this.props.className}
        onMouseDown={this.startTimer}
        onClick={this.executeOnClickFunctionIfNecessary}
      >
        {this.props.children}
      </Link>
    );
  }
}

export default DragAwareLink;
