import React from 'react';
import { resizeImage } from 'utils/getResizedImageUrl';
import { CategoryImg, Container, Name, StyledLink } from './Styled';

export interface CategoryListItemProps {
  name: string;
  materializedPath: string;
  imageUrlKey?: string;
  color?: string;
  slug: string;
  onClick?: Function;
}

class CategoryListItem extends React.PureComponent<CategoryListItemProps> {
  /**
   * Stops event propagation and calls the provided onClick
   * @param event
   */
  handleCardClick = (event) => {
    const { onClick } = this.props;
    event.stopPropagation();

    // ensures that onClicks still get run, even though event propagation is stopped
    if (onClick) {
      onClick();
    }
  };

  render() {
    return (
      <Container style={{ background: this.props.color }}>
        <StyledLink
          to={`/browse/${this.props.materializedPath}`}
          onClick={this.handleCardClick}
        >
          <CategoryImg
            imageSrc={resizeImage(240, 240, this.props.imageUrlKey)}
            alt="category"
          />
          <Name>{this.props.name}</Name>
        </StyledLink>
      </Container>
    );
  }
}

export default CategoryListItem;
