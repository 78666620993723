import React from 'react';
import { Icon } from 'components/Icon';
import { Text } from 'domains/web/components';
import { useTranslation } from 'react-i18next';
import { occasions } from './occasions';
import { OccasionWrapper, IconWrapper, Column, Row } from './Styled';

const Occasion = ({ type }: { type: string }) => {
  const { t } = useTranslation('Occasions');

  if (!type) return null;
  const occasion = occasions[type];
  if (!occasion) return null;

  return (
    <OccasionWrapper>
      <Column>
        <IconWrapper>
          <Icon name={occasion.iconName} size="xs" color="white" />
        </IconWrapper>
        <Row>
          <Text>{t(`${type}.title`, occasion.title)}</Text>
          <Text color="foregorund.muted">
            {t(`${type}.text`, occasion.text)}
          </Text>
        </Row>
      </Column>
    </OccasionWrapper>
  );
};

export default Occasion;
