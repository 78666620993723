import React from 'react';
import { Text } from 'domains/web/components';
import { resizeImage } from 'utils/getResizedImageUrl';
import {
  Wrapper,
  ImageWrapper,
  ImageContainer,
  StyledImage,
  ImageBackground,
} from './Styled';

type Props = {
  color?: string;
  imageUrlKey?: string;
  materializedPath: string;
  name?: string;
  onClick?: Function;
  slug: string;
};

export const CategoryCircle = ({
  color,
  imageUrlKey,
  materializedPath,
  name,
  onClick,
}: Props) => {
  /**
   * Stops event propagation and calls the provided onClick
   * @param event
   */
  const handleCardClick = (event) => {
    event.stopPropagation();

    // ensures that onClicks still get run, even though event propagation is stopped
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Wrapper to={`/browse/${materializedPath}`} onClick={handleCardClick}>
      <ImageWrapper data-testid="CategoryCircle">
        <ImageContainer>
          <ImageBackground bgColor={color} />
          <StyledImage
            imageSrc={resizeImage(320, 320, imageUrlKey)}
            alt="category"
          />
        </ImageContainer>
      </ImageWrapper>
      {!!name && (
        <Text
          variant="base_s"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          marginTop="xs"
          textAlign="center"
        >
          {name}
        </Text>
      )}
    </Wrapper>
  );
};
