import React from 'react';
import { resizeImage } from 'utils/getResizedImageUrl';
import { Wrapper, StyledLink, StyledImage, Name } from './Styled';

type Props = {
  name: string;
  imageKey?: string;
  slug: string;
  onClick?: Function;
};

export const TagCard = ({ name, imageKey, onClick, slug }: Props) => {
  /**
   * Stops event propagation and calls the provided onClick
   * @param event
   */
  const handleCardClick = (event) => {
    event.stopPropagation();

    // ensures that onClicks still get run, even though event propagation is stopped
    if (onClick) {
      onClick();
    }
  };

  if (!name || !slug || !imageKey) return null;

  return (
    <Wrapper data-testid="TagCard">
      <StyledLink to={`/tags/${slug}`} onClick={handleCardClick}>
        <StyledImage
          imageSrc={resizeImage(424, 230, imageKey)}
          alt={`${name}-tag`}
        />
      </StyledLink>
      <Name to={`/tags/${slug}`} onClick={handleCardClick}>
        {name}
      </Name>
    </Wrapper>
  );
};
