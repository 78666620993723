import styled from 'styled-components';
import { sizing } from 'design-system/style';
import DragAwareLink from 'components/DragAwareLink/DragAwareLink';
import LazyImage from 'design-system/Components/LazyImage';

export const Container = styled.div`
  border-radius: ${sizing(12)};
  color: white;
  max-width: ${sizing(240)};
  min-height: ${sizing(50)};
  min-width: ${sizing(200)};
  overflow: hidden;
  padding: ${sizing(10)} ${sizing(15)} ${sizing(18)} ${sizing(15)};
  position: relative;
  width: 100%;
`;

export const CategoryImg = styled(LazyImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Name = styled.h4`
  color: white;
  font-size: ${sizing(17)};
  letter-spacing: -0.28px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
  position: relative;
`;

export const StyledLink = styled(DragAwareLink)`
  text-decoration: none;
  width: 100%;
  height: 100%;
`;
