import React from 'react';
import { StarCard } from 'components';
import { RenderCollectionRow } from 'components/BrowseRow/CollectionView/collectionViewTypes';
import { StarCardUserFields } from 'types/src/api/models/user';
import * as Styled from './Styled';

export const UserStack: RenderCollectionRow<StarCardUserFields> = (
  user: StarCardUserFields,
  analyticsFunction: (event: React.MouseEvent) => void,
  index: number,
  isV2Enabled: boolean,
  preloadStarcardImage?: boolean,
  isKidsStarcard?: boolean
) => {
  return (
    <Styled.UserStackItem isPlaceholder={false} isKidsStarcard={isKidsStarcard}>
      <StarCard
        isV2Enabled
        preloadStarcardImage
        isKidsStarcard={isKidsStarcard}
        user={user}
        onClick={analyticsFunction}
        size={'medium'}
      />
    </Styled.UserStackItem>
  );
};

export default UserStack;
