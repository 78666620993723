import React from 'react';
import { CategoryCard as CompLibCategoryCard } from 'design-system/Components/CategoryCard';
import { TagCard as CompLibTagCard } from 'design-system/Components/TagCard';
import CategoryListItem from 'components/CategoryListItem';
import { CategoryCircle as CompLibCategoryCircle } from 'design-system/Components/CategoryCircle';
import { CategoryItem } from 'types/src/api/endpoints/explore/categories';
import { StackedItems } from 'types/src/api/endpoints/explore/utils';
import { Tag } from 'types/src/api/models/tags';
import * as Styled from './Styled';

export const CategoryCard = (
  category: CategoryItem,
  analyticsFunction: (event: React.MouseEvent) => void
) => (
  <CompLibCategoryCard
    avatars={category.contentThumbnails}
    materializedPath={category.materializedPath}
    name={category.name}
    onClick={analyticsFunction}
    slug={category.slug}
    key={category.slug}
  />
);

export const TagCard = (
  tag: Tag,
  analyticsFunction: (event: React.MouseEvent) => void
) => (
  <Styled.CategoryCard key={tag.slug}>
    <CompLibTagCard
      imageKey={tag.imageKey}
      name={tag.name}
      onClick={analyticsFunction}
      slug={tag.slug}
    />
  </Styled.CategoryCard>
);

export const CategoryList = (
  category: CategoryItem,
  analyticsFunction: (event: React.MouseEvent) => void
) => (
  <Styled.CategoryList key={category.slug}>
    <CategoryListItem
      color={category.primaryColor}
      imageUrlKey={category.image ? category.image.url : ''}
      materializedPath={category.materializedPath}
      name={category.name}
      onClick={analyticsFunction}
      slug={category.slug}
    />
  </Styled.CategoryList>
);

const CategoryCircleItem = ({
  category,
  analyticsFunction,
  shouldDisplayName = false,
}: {
  category: CategoryItem;
  analyticsFunction: (event: React.MouseEvent, category: CategoryItem) => void;
  shouldDisplayName?: boolean;
}) => {
  const handleCategoryCircleClick = React.useCallback(
    (event) => {
      if (analyticsFunction) {
        analyticsFunction(event, category);
      }
    },
    [category]
  );
  return (
    <Styled.CategoryCircle key={category.slug}>
      <CompLibCategoryCircle
        name={shouldDisplayName ? category.name : ''}
        imageUrlKey={category.image ? category.image.url : ''}
        color={category.primaryColor}
        slug={category.slug}
        materializedPath={category.materializedPath}
        onClick={handleCategoryCircleClick}
      />
    </Styled.CategoryCircle>
  );
};

export const CategoryCircle = (
  categoriesGroup: StackedItems<CategoryItem>,
  analyticsFunction: (event: React.MouseEvent, category: CategoryItem) => void
) => {
  try {
    const { items: categories } = categoriesGroup;
    return (
      <Styled.CategoryGroupedCard>
        {categories.map((category) => (
          <CategoryCircleItem
            category={category}
            analyticsFunction={analyticsFunction}
          />
        ))}
      </Styled.CategoryGroupedCard>
    );
  } catch (error) {
    return null;
  }
};

export const NamedCategoryCircle = (
  categoriesGroup: StackedItems<CategoryItem>,
  analyticsFunction: (event: React.MouseEvent, category: CategoryItem) => void
) => {
  try {
    const { items: categories } = categoriesGroup;
    return (
      <Styled.CategoryGroupedCard>
        {categories.map((category) => (
          <CategoryCircleItem
            category={category}
            analyticsFunction={analyticsFunction}
            shouldDisplayName
          />
        ))}
      </Styled.CategoryGroupedCard>
    );
  } catch (error) {
    return null;
  }
};
