import React from 'react';
import {
  Name,
  NameContainer,
  StyledDragAwareLink,
  Sub,
  UserImage,
  UserImageContainer,
  Wrapper,
} from './Styled';

export interface TalentListItemProps {
  id: string;
  imageUrlKey?: string;
  name: string;
  profession?: string;
}

const UserListItem = ({
  id,
  imageUrlKey,
  name,
  profession,
}: TalentListItemProps) => (
  <Wrapper>
    <StyledDragAwareLink to={`/${id}`}>
      <UserImageContainer>
        <UserImage
          width={240}
          height={240}
          quality={100}
          imageKey={imageUrlKey}
          alt={name}
        />
      </UserImageContainer>
      <NameContainer>
        <Name title={name}>{name}</Name>
        <Sub>{profession}</Sub>
      </NameContainer>
    </StyledDragAwareLink>
  </Wrapper>
);

export default UserListItem;
