import styled from 'styled-components';
import LazyImage from '../LazyImage';
import Link from '../Link';
import { animations, color as globalColors, media, sizing } from '../../style';

export const ImageWrapper = styled.div`
  color: ${globalColors.solids.WHITE};
  overflow: hidden;
  height: ${sizing(104)};
  width: ${sizing(104)};

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    height: ${sizing(160)};
    width: ${sizing(160)};
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
`;

export const StyledImage = styled(LazyImage)`
  display: block;
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;

export const ImageBackground = styled.div<{ bgColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ bgColor }) => bgColor || '#131431'};
  border-radius: 50%;
`;

export const Wrapper = styled(Link)`
  color: ${globalColors.solids.WHITE};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  transform: translate3d(0, 0, 0);
  transition: transfrom ${animations.transition.NORMAL}ms
    cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    transform: translate3d(0, -0.3rem, 0);
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;
