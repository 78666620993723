import styled from 'styled-components';
import LazyImage from '../LazyImage';
import Link from '../Link';
import { color as globalColors, fonts, sizing } from '../../style';

export const Wrapper = styled.div`
  background: #131431;
  border-radius: ${sizing(6)};
  height: ${sizing(115)};
  width: 100%;
  color: ${globalColors.solids.WHITE};
  overflow: hidden;
  min-width: ${sizing(200)};
  max-width: ${sizing(240)};
  padding: ${sizing(24)} ${sizing(15)} ${sizing(10)} ${sizing(15)};
  position: relative;
`;

export const StyledLink = styled(Link)`
  opacity: 50%;
  text-decoration: none !important;
  width: 100%;
  height: 100%;
`;

export const StyledImage = styled(LazyImage)`
  image-rendering: -webkit-optimize-contrast;
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

// On firefox the opacity from StyledLink gets applied to name,
// that is why Name is its own link instead of being wrapped by StyledLink
export const Name = styled(Link)`
  ${fonts.boldText['400']};
  color: ${globalColors.solids.WHITE};
  letter-spacing: -0.28px;
  position: relative;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-decoration: none !important;

  &:hover {
    color: ${globalColors.solids.WHITE};
  }
`;
