import styled from 'styled-components';

import { sizing } from 'design-system/style';

export const OccasionWrapper = styled.div`
  margin-top: ${sizing(8)};
  height: ${sizing(80)};
  width: 100%;
  display: block;
  padding-right: ${sizing(12)};
`;

export const IconWrapper = styled.div`
  margin-top: ${sizing(2)};
  margin-right: ${sizing(8)};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
