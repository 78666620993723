import React from 'react';
import { Link as RRDLink, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  href?: string;
}

const Link = ({ href, ...props }: Props) => {
  if (href) {
    return (
      <a href={href} {...props}>
        {props.children}
      </a>
    );
  }

  return <RRDLink {...props}>{props.children}</RRDLink>;
};

export default React.memo(Link);
