import { sizing } from 'design-system/style';
import styled from 'styled-components';
import DragAwareLink from 'components/DragAwareLink/DragAwareLink';
import LazyImage from 'design-system/Components/LazyImage';

export const Wrapper = styled.div`
  margin: ${sizing(10)};
`;

export const StyledDragAwareLink = styled(DragAwareLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const UserImageContainer = styled.div`
  background: #e2e2e2;
  border-radius: 50px;
  display: block;
  margin-right: 15px;
  min-height: 80px;
  min-width: 80px;
  overflow: hidden;
  position: relative;
`;

export const UserImage = styled(LazyImage)`
  width: 80px;
  height: 80px;
`;

export const NameContainer = styled.div`
  overflow: hidden;
`;

export const Name = styled.h4`
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.28px;
  line-height: 19px;
  margin-bottom: 2px;
  margin-right: 15px;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Sub = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: -0.28px;
  color: #adaeb5;
  margin-bottom: 0;
`;
