import { Link } from 'react-router-dom';
import React from 'react';

import { ContentThumbnail } from 'types/src/api/endpoints/explore/categories';
import { Box, Image, Text } from 'domains/web/components';
import { useBrowseOverrides } from 'domains/c4b/shared/consumer/components/BrowseOverrides/BrowseOverrides';

type Props = {
  name: string;
  materializedPath: string;
  slug: string;
  onClick?: (event?: React.MouseEvent<Element, MouseEvent>) => void;
  avatars: ContentThumbnail[];
};

export const CategoryCard = React.memo(
  ({ name, materializedPath, onClick, avatars = [] }: Props) => {
    const handleCardClick = React.useCallback(() => {
      if (onClick) {
        onClick();
      }
    }, [onClick]);

    const { browsePrefix } = useBrowseOverrides();

    const [avatarCenter] = avatars;

    return (
      <Box
        as={Link}
        data-testid="CategoryCard"
        // @ts-ignore TODO: Remove once the Theme is no longer wrapping the element
        to={`${browsePrefix}/browse/${materializedPath}`}
        onClick={handleCardClick}
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Image
          borderRadius="50%"
          width={106}
          height={106}
          imageUrlKey={avatarCenter?.image?.key}
          alt={name}
        />
        <Text
          variant="base_s"
          modifier="interactive"
          color="white"
          mt="md"
          textDecoration="none"
        >
          {name}
        </Text>
      </Box>
    );
  }
);
