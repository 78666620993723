import styled from 'styled-components';
import { colors } from 'design-system/style/color';
import { fonts, media, sizing } from 'design-system/style';
import { theme } from 'domains/web/theme/og';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ScrollRowActions = styled.div<{ hideActions: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${sizing(60)};
  white-space: nowrap;

  ${({ hideActions }) =>
    hideActions &&
    `@media screen and (min-width: ${media.queries.TABLET}) {
      display: none;
      }
  }`}
`;

export const SeeAllLink = styled.div`
  ${fonts.mediumText['300']};
  color: #21cfe6;

  &:hover,
  &:active {
    color: #0aa9be;
  }
`;

export const SeeAllLinkV2 = styled.div`
  color: ${theme.colors.foreground.default};
  font-size: ${theme.fonts.fontSizes.base_s};
  white-space: nowrap;

  text-decoration-line: underline;

  &:hover,
  &:active {
    cursor: pointer;
    color: ${theme.colors.foreground.default};
  }
`;

export const Paginator = styled.div`
  display: none;
  @media (min-width: ${media.queries.TABLET}) {
    display: block;
    color: ${theme.colors.foreground.muted};
    font-size: ${theme.fonts.fontSizes.base_s};
    white-space: nowrap;
  }
`;

export const HeroTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${sizing(24)};
`;

export const HeroTitle = styled.h1`
  color: ${colors.solids.WHITE};
  ${fonts.fontFamilies.geist}
  font-size: 24px;
  line-height: 28px;
  font-weight: 800;
  letter-spacing: -0.2px;

  margin-bottom: ${sizing(8)};
  margin-top: 0;
  text-align: center;

  @media (min-width: ${media.queries.TABLET}) {
    ${fonts.fontFamilies.geist}
    font-size: 44px;
    font-weight: bold;
    line-height: 48px;
    margin-top: ${sizing(6)};
  }
`;

export const HeroCaption = styled.div`
  color: ${colors.solids.WHITE};
  ${fonts.fontFamilies.geist}
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.1px;
  font-weight: normal;

  margin-bottom: 0;
  margin-top: 0;
  text-align: center;

  @media (min-width: ${media.queries.TABLET}) {
    ${fonts.fontFamilies.geist}
    font-size: 20px;
    font-weight: medium;
  }
`;
