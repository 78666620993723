import * as React from 'react';
import { BrowseLink as BrowseLinkType } from 'types/src/api/endpoints/explore/utils';
import { CollectionRow } from 'types/src/api/endpoints/explore/browseRow';
import analytics from 'analytics';
import BrowseLink from '../../BrowseLink';
import WithItemVisibility from '../VisibilityContext/WithItemVisibility';
import * as Styled from './Styled';

interface Props extends BrowseLinkType {
  index: number;
  metadata: CollectionRow['metadata'];
  rowIndex: number;
  rowKey: string;
  title: BrowseLinkType;
}

// Function for rendering the end card. Sets up an analytics function using props to determine metadata
const EndCard = ({
  linkKey,
  linkType,
  metadata,
  rowIndex,
  rowKey,
  text,
  title,
}: Props) => {
  const analyticsFunction = React.useCallback(() => {
    const data = {
      eventName: 'Navigate',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Text',
        containerType: 'Basic-Shelf',
      },
      positionDtl: {
        algorithmKey: metadata.algorithmKey,
        collectionTitle: title,
        row: rowIndex,
        rowKey,
        rowLabelText: metadata.category || metadata.tag,
      },
      sourceDtl: {
        sourceType: 'Home',
        sourcePath: 'See All',
      },
    } as const;

    analytics.trackAction(data);
  }, [metadata, rowKey, rowIndex, title]);

  return (
    <Styled.EndCard onClick={analyticsFunction} key={`${title.text}-endCard`}>
      <BrowseLink text={text} linkKey={linkKey} linkType={linkType}>
        <Styled.EndCard>
          <Styled.EndCardText>{text}</Styled.EndCardText>
        </Styled.EndCard>
      </BrowseLink>
    </Styled.EndCard>
  );
};

const WithVisibilityContextEndCard = ({ ...props }: Props) => {
  return (
    <WithItemVisibility index={props.index}>
      <EndCard {...props} />
    </WithItemVisibility>
  );
};

export default React.memo(WithVisibilityContextEndCard);
