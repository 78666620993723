import * as React from 'react';
import get from 'lodash/get';
import { CollectionRow } from 'types/src/api/endpoints/explore/browseRow';

import {
  BrowseLink,
  ContentType,
  DisplayType,
  ItemContentType,
  ContentTypeValueMap,
} from 'types/src/api/endpoints/explore/utils';
import analytics from 'analytics';

import {
  TileImpressionContext,
  withImpressionContext,
} from 'analytics/components/withImpressionContext';
import { RenderCollectionRow } from 'components/BrowseRow/CollectionView/collectionViewTypes';
import { hasStackedItems } from 'components/BrowseRow/utils';
import algoliaAnalytics from 'analytics/clients/algoliaAnalytics';
import { StarCardUserFields } from 'types/src/api/models/user';
import { CategoryItem } from 'types/src/api/endpoints/explore/categories';
import {
  CategoryCard,
  CategoryCircle,
  CategoryList,
  NamedCategoryCircle,
  TagCard,
  UserCard,
  UserList,
  UserStack,
  VideoPlayer,
} from '../renderers';
import WithItemVisibility from '../VisibilityContext/WithItemVisibility';
import * as Styled from './Styled';

const DISPLAY_MAP: {
  [key in ContentType]: {
    [K in DisplayType]?: RenderCollectionRow<ContentTypeValueMap[key]>;
  };
} = {
  user: {
    scrollRow: UserCard,
    row: UserList,
    stackedScrollRow: UserStack,
  },
  category: {
    stackedScrollRow: CategoryCircle,
    namedStackedScrollRow: NamedCategoryCircle,
    scrollRow: CategoryCard,
    row: CategoryList,
  },
  tag: {
    scrollRow: TagCard,
  },
  post: {
    scrollRow: null,
    heroRow: null,
  },
  merch: {},
  video: {
    scrollRow: VideoPlayer,
  },
  liveEvent: {},
} as const;

const NotImplemented: RenderCollectionRow = () => null;

// how many Starcards on a row should immediate load their images
const PRELOAD_STARCARD_WIDTH = 8;

interface CollectionItemProps {
  item: ItemContentType;
  index: number;
  rowIndex: number;
  metadata: CollectionRow['metadata'];
  preloadStarcardImages: boolean;
  contentType: CollectionRow['contentType'];
  displayType: DisplayType;
  contents: Record<string, string>;
  title: BrowseLink;
  isKidsStarcard: boolean;
  isUserOrMerchScrollRow: boolean;
  isV2Enabled: boolean;
  rowKey: string;
}

const setupAnalyticsFunction =
  ({
    item: providedItem,
    metadata,
    title,
    index,
    rowIndex,
    contents,
    rowKey,
  }: {
    item: ItemContentType;
    metadata: CollectionRow['metadata'];
    title: BrowseLink;
    index: number;
    rowIndex: number;
    contents: Record<string, string>;
    rowKey: string;
  }) =>
  (event: React.MouseEvent, clickedItem?: ItemContentType) => {
    const item = clickedItem || providedItem;
    const data = {
      eventName: 'Navigate',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Card',
        containerType: 'Carousel',
      },
      positionDtl: {
        deploymentKey: metadata.deploymentKey,
        algorithmKey: metadata.algorithmKey,
        rowLabelText: title.text,
        rowKey,
        rowLabelType: title.linkType,
        col: index,
        row: rowIndex,
      },
      sourceDtl: {
        sourceType: 'Home',
        sourceValue: metadata.category || metadata.tag,
      },
    } as const;

    if ('username' in item) {
      const charitySettings = item?.talentSettings?.charitySettings;
      const charityId = charitySettings?.campaign?.charityId;
      Object.assign(data, {
        targetDtl: {
          targetType: 'Profile',
          targetPath: item.username,
          targetKey: 'User',
          targetValue: item._id,
          contents,
        },
        eventDtl: {
          eventType: 'Click',
          contents: {
            price: item.price,
            isLive: Boolean(item.isLive),
            isOnline: Boolean(item.isOnline),
            isGivingToCharity: Boolean(charityId),
          },
        },
      });
      algoliaAnalytics.trackStarCardClick({
        user: item as StarCardUserFields,
        index,
      });
    } else if ('materializedPath' in item) {
      Object.assign(data, {
        targetDtl: {
          targetType: 'Category-Browse',
          targetPath: item.materializedPath,
          targetKey: 'Category',
          targetValue: item._id,
          contents,
        },
      });
      algoliaAnalytics.trackCategoryClick({ item: item as CategoryItem });
    } else if ('product_url' in item) {
      Object.assign(data, {
        objectDtl: {
          ...data.objectDtl,
          objectName: 'Merch card',
        },
        sourceDtl: {
          ...data.sourceDtl,
          sourceType: 'Profile',
        },
        targetDtl: {
          targetType: 'Merch-Browse',
          targetPath: item.product_url,
          targetKey: 'Merch',
          targetValue: item._id,
          contents: item,
        },
      });
    } else if ('slug' in item) {
      Object.assign(data, {
        targetDtl: {
          targetType: 'Tag-Browse',
          targetPath: item.slug,
          targetKey: 'Tag',
          targetValue: item._id,
          contents,
        },
      });
    } else if (
      'type' in item &&
      (item.type === 'order' || item.type === 'reaction')
    ) {
      Object.assign(data, {
        eventDtl: {
          ...data.eventDtl,
          contents: {
            _id: item._id,
            type: item.type,
          },
        },
        objectDtl: {
          ...data.objectDtl,
          objectType: item.type === 'order' ? 'Video' : 'ReactionVideo',
        },
        targetDtl: {
          targetType: 'VideoPlayerCollectionItem',
          targetPath: item._id,
          targetKey: 'Video',
          targetValue: item._id,
        },
        sourceDtl: {
          ...data.sourceDtl,
          sourceType: 'Browse',
        },
      });
    }

    analytics.trackAction(data);
  };

// Function for rendering individual items. Sets up an analytics function using props to determine metadata
const CollectionItem = (props: CollectionItemProps) => {
  const {
    contents,
    contentType,
    displayType,
    index,
    isKidsStarcard,
    isUserOrMerchScrollRow,
    isV2Enabled = false,
    item,
    metadata,
    preloadStarcardImages,
    rowIndex,
    rowKey,
    title,
  } = props;

  const componentFunction: RenderCollectionRow = get(
    DISPLAY_MAP,
    [contentType, displayType],
    NotImplemented
  );

  const analyticsFunction = React.useCallback(
    setupAnalyticsFunction({
      item,
      title,
      metadata,
      index,
      rowIndex,
      contents,
      rowKey,
    }),
    []
  );

  const preloadStarcardImage =
    preloadStarcardImages && index < PRELOAD_STARCARD_WIDTH;

  const component = componentFunction(
    item,
    analyticsFunction,
    index,
    isV2Enabled,
    preloadStarcardImage,
    isKidsStarcard
  );

  return hasStackedItems(contentType, displayType) ||
    (contentType === 'user' && displayType === 'stackedScrollRow') ||
    contentType === 'video' ? (
    <>{component}</>
  ) : (
    <Styled.CardContainer
      key={item._id}
      isFirstItem={index === 0}
      isUserOrMerchScrollRow={isUserOrMerchScrollRow}
    >
      {component}
    </Styled.CardContainer>
  );
};
const withImpressionContextCollectionItem = withImpressionContext(
  (props: CollectionItemProps): TileImpressionContext => {
    return {
      tileHorizontalCoordinate: props.index,
    };
  }
)(React.memo(CollectionItem));

const MemoizedCollectionItem = React.memo(withImpressionContextCollectionItem);

const WithVisibilityContextCollectionItem = (props: CollectionItemProps) => {
  return (
    <WithItemVisibility index={props.index}>
      <MemoizedCollectionItem {...props} />
    </WithItemVisibility>
  );
};

export default React.memo(WithVisibilityContextCollectionItem);
