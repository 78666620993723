import React from 'react';
import { useOnImpression } from 'utils/hooks';
import analytics from 'analytics';

type Props = {
  rowKey: string;
  analyticsData: { rowLabelText: string; row: number; rowKey: string };
};

const RowEndMarker = ({ rowKey, analyticsData }: Props) => {
  const { observedElementRef } = useOnImpression(() =>
    analytics.trackSystem({
      eventName: 'View',
      eventDtl: {
        eventType: 'Row end reached',
      },
      objectDtl: {
        objectType: 'Shelf',
        objectName: 'End Reached',
        objectVersion: rowKey,
      },
      positionDtl: analyticsData,
    })
  );
  return <div ref={observedElementRef} />;
};

export default RowEndMarker;
