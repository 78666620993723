import React from 'react';
import { Occasion } from 'components/Occasion';
import { VideoPlayerCollectionItem } from 'components/VideoPlayerCollection';
import { VideoPlayerCollectionItem as VideoPlayerCollectionItemType } from 'types/src/api/endpoints/explore/utils';
import { RenderCollectionRow } from '../../collectionViewTypes';

export const VideoPlayer: RenderCollectionRow<VideoPlayerCollectionItemType> = (
  item,
  analyticsFunction,
  index,
  isV2Enabled,
  preloadStarcardImage
) => {
  return 'order' in item ? (
    <>
      <VideoPlayerCollectionItem
        // @ts-expect-error because of loadable, we cannot infer the types of this component
        item={item}
        onClick={analyticsFunction}
        index={index}
        sourceType="Category-Browse"
      />
      <Occasion type={item?.order?.occasion} />
    </>
  ) : null;
};
