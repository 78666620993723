import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import analytics from 'analytics';
import { LoadableClassComponent } from '@loadable/component';
import {
  ItemContentType,
  StackedItems,
} from 'types/src/api/endpoints/explore/utils';
import {
  TileImpressionContext,
  withImpressionContext,
} from 'analytics/components/withImpressionContext';
import { selectors as clientSelectors } from 'state/modules/client';
import { selectors as routerSelectors } from 'state/modules/router';
import { useKidsExperience } from 'utils/hooks/useKidsExperience';

import {
  hasStackedItems,
  parseItemsToStackedItems,
  parseStackedItemsToItems,
} from 'components/BrowseRow/utils';
import { CollectionRow } from 'types/src/api/endpoints/explore/browseRow';
import { Box } from 'domains/web/components';
import { CollectionViewProps } from './collectionViewTypes';
import { RowHeader } from './RowHeader';
import RowEndMarker from './RowEndMarker/RowEndMarker';
import { ScrollArrowLeft, ScrollArrowRight } from './ScrollArrows';
import EndCard from './EndCard/EndCard';
import CollectionItem from './CollectionItem';
import VisibilityProvider from './VisibilityContext';
import * as Styled from './Styled';
import { UserStackItem } from './renderers/UserStack/Styled';
import { VideoPlayerCollection } from '../VideoPlayerCollection';

const CollectionView = (
  props: CollectionViewProps & {
    isV2StarcardEnabled: boolean;
    isCollectionV2: boolean;
  }
) => {
  const {
    isV2StarcardEnabled = false,
    isCollectionV2 = false,
    rowIndex,
    source,
    preloadStarcardImages,
    headerVariant,
  } = props;
  const { metadata = {}, items, key, contentType } = source;
  const { displayType, title, endCard } = source.container;
  const isUserOrMerchScrollRow =
    (contentType === 'user' || contentType === 'merch') &&
    displayType === 'scrollRow';
  const onKidsTalentPage = useKidsExperience();
  const isKidsStarcard =
    useSelector(routerSelectors.isKidsPage) ||
    (onKidsTalentPage && key === 'related-talent');

  const isC4BBriefcasePage = useSelector(
    routerSelectors.isBusinessBriefcasePage
  );

  useEffect(() => {
    if (metadata?.algorithmKey === 'model') {
      const data = {
        eventName: 'Environment',
        eventClass: 'ComponentLoad',
        positionDtl: {
          row: rowIndex,
          rowLabel: title.text,
          rowKey: key,
          deploymentKey: metadata.deploymentKey,
        },
        sourceDtl: {
          sourceType: 'Home',
          sourceUrl: 'www.cameo.com',
        },
      } as const;

      analytics.trackSystem(data);
    }
  });

  const handleTitleClick = (ctaTitle) => {
    const targetKey = {
      external: 'External',
      category: 'Category',
      tag: 'Tag',
      site: 'Site',
    };

    const targetType = {
      category: 'Category-Browse',
      external: 'External-Browse',
      tag: 'Tag-Browse',
    };

    analytics.trackAction({
      eventName: 'Navigate',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Button',
        objectName: ctaTitle,
      },
      targetDtl: {
        rowKey: key,
        targetKey: targetKey[title.linkType],
        targetPath:
          title.linkType === 'external' ? title.externalUrl : title.linkKey,
        targetType: targetType[title.linkType],
        targetValue: title.text,
      },
    });
  };

  const contents = React.useMemo(() => {
    const contentItems = hasStackedItems(contentType, displayType)
      ? parseStackedItemsToItems(items as StackedItems<ItemContentType>[])
      : items;
    return (contentItems as ItemContentType[]).reduce((agg, listItem, idx) => {
      return {
        ...agg,
        [idx]: listItem._id,
      };
    }, {});
  }, [items]);

  const analyticsData = React.useMemo(
    () => ({
      row: rowIndex,
      rowKey: source.key,
      rowLabelText: source.container?.title?.text,
    }),
    []
  );

  const components = React.useMemo(() => {
    // .map in TS doesn't work on unions, hence the "as" here - the true type of items is UserItem[] | CategoryItem[]
    const _components = (items as ItemContentType[]).map((item, index) => {
      return (
        <CollectionItem
          contents={contents}
          contentType={contentType}
          displayType={displayType}
          index={index}
          isKidsStarcard={isKidsStarcard}
          isUserOrMerchScrollRow={isUserOrMerchScrollRow}
          isV2Enabled={isV2StarcardEnabled}
          item={item}
          key={item._id}
          metadata={metadata}
          preloadStarcardImages={preloadStarcardImages}
          rowIndex={rowIndex}
          rowKey={source.key}
          title={title}
        />
      );
    });

    const componentsWithExtras = [..._components];

    if (
      endCard &&
      endCard.text &&
      displayType === 'scrollRow' &&
      !isUserOrMerchScrollRow
    ) {
      componentsWithExtras.push(
        <EndCard
          {...endCard}
          index={items.length}
          key={key}
          metadata={metadata}
          rowIndex={rowIndex}
          rowKey={source.key}
          title={title}
        />
      );
    }

    // These items are flex: 1 item to keep the cards sizing consistent
    // when there is not enough items to complete the row
    if (displayType === 'stackedScrollRow' && contentType === 'user') {
      for (let i = 0; i < 4; i += 1) {
        componentsWithExtras.push(
          <UserStackItem isPlaceholder key={`item-placeholder-${i}`} />
        );
      }
    }

    componentsWithExtras.push(
      <RowEndMarker
        analyticsData={analyticsData}
        key={key}
        rowKey={source.key}
      />
    );

    return componentsWithExtras;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);

  let CollectionViewComponent:
    | React.FC<{
        children: React.ReactNode;
      }>
    | LoadableClassComponent<any> = () => null;

  if (isC4BBriefcasePage) {
    // TODO: C4B seems to only use this + star cards for collection view. This isn't pretty but this whole if is a mess
    CollectionViewComponent = Styled.C4BScrollRowWrapper;
  } else {
    if (displayType === 'scrollRow') {
      CollectionViewComponent = Styled.ScrollRow;
    }

    if (displayType === 'stackedScrollRow') {
      CollectionViewComponent = Styled.StackedScrollRow;
      if (contentType === 'user') {
        CollectionViewComponent = Styled.UserStackedScrollRow;
      }
    }

    if (displayType === 'namedStackedScrollRow') {
      CollectionViewComponent = Styled.StackedScrollRow;
    }

    if (isUserOrMerchScrollRow) {
      CollectionViewComponent = Styled.FullWidthScrollRow;
    }

    if (displayType === 'row') {
      CollectionViewComponent = Styled.ViewRow;
    }

    if (displayType === 'column') {
      CollectionViewComponent = Styled.ViewColumn;
    }

    if (displayType === 'heroRow') {
      CollectionViewComponent = Styled.HeroRow;
    }

    if (contentType === 'video') {
      CollectionViewComponent = VideoPlayerCollection;
    }
  }

  // remove when ContentFeed is GA
  const isContentFeedPost = source.contentType === 'post';
  const isContentFeedFeatureEnabled = true;

  const onBrowseLinkClick = React.useCallback(handleTitleClick, [title, key]);

  if (isContentFeedPost && !isContentFeedFeatureEnabled) {
    return null;
  }

  return (
    <Styled.Container isCollectionV2={isCollectionV2}>
      {contentType !== 'video' && (
        <RowHeader
          isCollectionV2={isCollectionV2}
          headerVariant={headerVariant}
          title={title}
          onBrowseLinkClick={onBrowseLinkClick}
          shouldDisplayArrows={!hasStackedItems(contentType, displayType)}
        />
      )}
      <Styled.CollectionContainer>
        {/* Avoid the `CollectionViewComponent` overflows container boundaries */}
        <Box display="inline-grid">
          {hasStackedItems(contentType, displayType) && (
            <Styled.ArrowLeftWrapper>
              <ScrollArrowLeft variant="secondary" />
            </Styled.ArrowLeftWrapper>
          )}
          <CollectionViewComponent>{components}</CollectionViewComponent>
          {hasStackedItems(contentType, displayType) && (
            <Styled.ArrowRightWrapper>
              <ScrollArrowRight variant="secondary" />
            </Styled.ArrowRightWrapper>
          )}
        </Box>
      </Styled.CollectionContainer>
    </Styled.Container>
  );
};

const WithVisibilityContextCollectionView = (
  props: CollectionViewProps & {
    isV2StarcardEnabled: boolean;
    isCollectionV2: boolean;
  }
) => {
  const { rowIndex, source: _source } = props;
  const { container, items: _items, contentType } = _source;
  const { displayType } = container;
  const isMobile: boolean = useSelector(clientSelectors.getIsMobile);
  const isTablet: boolean = useSelector(clientSelectors.getIsTablet);

  const items = React.useMemo(() => {
    if (hasStackedItems(contentType, displayType)) {
      // Circle categories shelf has stacked cards.
      // If the shelf has 3 or more items on a mobile device, we display the
      // cards stacked. If less than 6, the cards are shown in 1 row
      const itemArrayLength =
        (isMobile || isTablet) && _items.length >= 3 ? 2 : 1;

      return parseItemsToStackedItems(
        _items as ItemContentType[],
        itemArrayLength
      );
    }

    return _items;
  }, [_items, isMobile]);

  const source = { ..._source, items } as CollectionRow;
  const newProps = { ...props, source };

  const analyticsData = React.useMemo(
    () => ({
      rowLabelText: source?.container?.title?.text,
      rowKey: source.key,
      row: rowIndex,
    }),
    []
  );

  return (
    <VisibilityProvider
      numItems={props.source.items.length}
      analyticsData={analyticsData}
    >
      <CollectionView {...newProps} />
    </VisibilityProvider>
  );
};

const withImpressionContextAndVisibilityContextCollectionView =
  withImpressionContext(
    (
      props: CollectionViewProps & {
        isV2StarcardEnabled?: boolean;
        isCollectionV2?: boolean;
      }
    ): TileImpressionContext => {
      return {
        shelfLayout: 'row',
        shelfTitle: props.source.container.title.text,
        shelfCoordinate: props.rowIndex,
        shelfTileRanking: props.source?.metadata?.algorithmKey
          ? 'algorithmic'
          : 'hand_picked',
      };
    }
  )(React.memo(WithVisibilityContextCollectionView));

export default React.memo(
  withImpressionContextAndVisibilityContextCollectionView
);
