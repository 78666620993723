import { fonts, sizing } from 'design-system/style';
import styled from 'styled-components';

export const EndCard = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(160, 162, 181, 0.2);
  max-width: ${sizing(165)};
  min-width: ${sizing(165)};
`;

export const EndCardText = styled.div`
  ${fonts.boldText['300']};
  color: #4d4d4d;
`;
