import {
  BrowseRow,
  CollectionRow,
} from 'types/src/api/endpoints/explore/browseRow';
import chunk from 'lodash/chunk';
import {
  DisplayType,
  ItemContentType,
  StackedItems,
} from 'types/src/api/endpoints/explore/utils';

export function extractTitleFromBrowseRow(browseRow: BrowseRow): string {
  if (browseRow?.rowTitle?.title) {
    return browseRow.rowTitle?.title ?? '';
  }

  if (browseRow?.type === 'collection') {
    return browseRow.container?.title?.text ?? '';
  }

  if (
    browseRow?.type === 'spotlight' ||
    browseRow?.type === 'quizModule' ||
    browseRow?.type === 'sixPack'
  ) {
    // type check added to ensure returning a string in spite of malformed data
    return typeof browseRow?.title === 'string' ? browseRow?.title : '';
  }

  return '';
}

export function extractShortTitleFromBrowseRow(browseRow: BrowseRow): string {
  return browseRow?.rowTitle?.shortTitle ?? '';
}

export function hasStackedItems(
  contentType: CollectionRow['contentType'],
  displayType: DisplayType
) {
  return (
    (displayType === 'stackedScrollRow' ||
      displayType === 'namedStackedScrollRow') &&
    contentType === 'category'
  );
}

export function parseItemsToStackedItems(
  items: ItemContentType[],
  stackedItemsLength: number
): StackedItems<ItemContentType>[] {
  return chunk(items, stackedItemsLength).map(
    (chunkedItems) =>
      ({
        _id: chunkedItems.map((chunkedItem) => chunkedItem._id).join('-'),
        items: chunkedItems,
      } as StackedItems<ItemContentType>)
  );
}

export function parseStackedItemsToItems(
  stackedItems: StackedItems<ItemContentType>[]
) {
  try {
    return [].concat(...stackedItems.map((item) => item.items));
  } catch (error) {
    return stackedItems;
  }
}
