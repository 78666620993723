import { CameoEnums } from 'types/src/utils/enums';
import { IconName } from 'components/Icon';

// If creating or updating an occasion, be sure to update i18n mappings
// 'title' and 'text' are i18n fallbacks
export const occasions: {
  [key in keyof typeof CameoEnums.Occasion]?: {
    iconName: IconName;
    title: string;
    text: string;
  };
} = {
  // @ts-expect-error
  cumpleanos: {
    iconName: 'birthday-cake',
    title: 'Cumpleaños',
    text: 'Dale un mensaje especial en su gran día',
  },
  diaDeEscuela: {
    iconName: 'book',
    title: 'Ten un gran día en la escuela',
    text: 'Envíalos a la escuela con un impulso extra',
  },
  horaDeDormir: {
    iconName: 'bed',
    title: 'Hora de acostarse',
    text: 'Mejora tu rutina de dormir',
  },
  reponerse: {
    iconName: 'plus',
    title: 'Reponerse',
    text: 'Ayúdalos a sonreír en los días difíciles',
  },
  /**
   * Occasions in english
   */
  birthday: {
    iconName: 'birthday-cake',
    title: 'Birthday wishes',
    text: 'Give a special message on their big day',
  },
  bedtime: {
    iconName: 'bed',
    title: 'Bedtime routine',
    text: 'Level up your bedtime routine',
  },
  pottyTraining: {
    iconName: 'toilet',
    title: 'Potty training',
    text: 'A little support goes a long way',
  },
  toothbrushTime: {
    iconName: 'toothbrush',
    title: 'Toothbrush time',
    text: 'Encourage them to keep up their routine',
  },
  christmas: {
    iconName: 'christmas',
    title: 'Christmas cheer',
    text: 'Add a magical moment to your traditions',
  },
  hanukkah: {
    iconName: 'hanukkah',
    title: 'Hanukkah joy',
    text: 'Warm their hearts with joy',
  },
  kwanzaa: {
    iconName: 'kwanzaa',
    title: 'Kwanzaa',
    text: 'Celebrate family and loved ones',
  },
  thanksgiving: {
    iconName: 'thanksgiving',
    title: 'Thanksgiving',
    text: 'Get the gratitude going',
  },
  seasonsGreetings: {
    iconName: 'snowflake',
    title: "Season's greetings",
    text: 'Cue the happy tears',
  },
  halloween: {
    iconName: 'halloween',
    title: 'Halloween',
    text: 'Perfect for tick or treaters',
  },
  easter: {
    iconName: 'easter',
    title: 'Easter',
    text: 'Add a sweet message to their basket',
  },
  diwali: {
    iconName: 'diwali',
    title: 'Diwali',
    text: 'Cue the festival celebrations',
  },
  school: {
    iconName: 'book',
    title: 'Have a great school day',
    text: 'Send them to school with an extra boost',
  },
  getWell: {
    iconName: 'plus',
    title: 'Get well',
    text: 'Help them smile on days when it is hard',
  },
  encouragement: {
    iconName: 'thumbsUp',
    title: 'Encouragement',
    text: 'Reward wins both big and small',
  },
  weLoveYou: {
    iconName: 'heart',
    title: 'We love you',
    text: 'Share your love in a new way',
  },
  homework: {
    iconName: 'book',
    title: 'Homework',
    text: 'Encourage them to keep up their routine',
  },
};
