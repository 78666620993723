import React from 'react';
import { StarCard } from 'components';
import { StarCardUserFields } from 'types/src/api/models/user';
import { RenderCollectionRow } from 'components/BrowseRow/CollectionView/collectionViewTypes';
import * as Styled from './Styled';

type Props = {
  user: StarCardUserFields;
  analyticsFunction: (event: React.MouseEvent) => void;
  isV2Enabled: boolean;
  isKidsStarcard?: boolean;
  preloadStarcardImage?: boolean;
};

const UserCardContainerBase = ({
  user,
  analyticsFunction,
  preloadStarcardImage,
  isV2Enabled,
  isKidsStarcard,
}: Props) => {
  return (
    <Styled.UserCardContainer key={user._id}>
      <StarCard
        user={user}
        onClick={analyticsFunction}
        preloadStarcardImage={preloadStarcardImage}
        isV2Enabled={isV2Enabled}
        isKidsStarcard={isKidsStarcard}
      />
    </Styled.UserCardContainer>
  );
};

const UserCard: RenderCollectionRow<StarCardUserFields> = (
  user: StarCardUserFields,
  analyticsFunction: (event: React.MouseEvent) => void,
  index: number,
  isV2Enabled: boolean,
  preloadStarcardImage?: boolean,
  isKidsStarcard?: boolean
) => (
  <UserCardContainerBase
    user={user}
    analyticsFunction={analyticsFunction}
    isV2Enabled={isV2Enabled}
    isKidsStarcard={isKidsStarcard}
    preloadStarcardImage={preloadStarcardImage}
  />
);

export default UserCard;
